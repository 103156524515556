.dark-theme {
  .ant-layout,
  .header,
  .ant-menu,
  .ant-layout-sider {
    background: #252325;
    color: rgba(255, 255, 255, 0.85);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }

  .ant-layout-header {
    .triumph-logo {
      background-image: url("../public/logo_dark.svg");
      width: 135px;
      height: 26px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .content-bg {
    background: #000;
  }

  .ant-menu.ant-menu:not(.ant-menu-horizontal) > .ant-menu-item-selected {
    background: linear-gradient(278.93deg, #f54e03 -15.33%, #fd9f48 92.09%),
      linear-gradient(47.13deg, #f44a00 1.82%, #fd9f48 122.88%), #252325;
    border-radius: 5px;
  }

  .ant-menu .ant-menu-inline.ant-menu-sub {
    background: #190b03;
  }

  .ant-menu.ant-menu:not(.ant-menu-horizontal)
    .ant-menu-sub
    .ant-menu-item-selected {
    color: #fff;
  }

  .user-search-multi-select {
    background: #444244;
    color: rgba(255, 255, 255, 0.5);
  }

  .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border: none;
    background: #000;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover {
    color: #fff;
  }

  .ant-tabs-nav {
    background: #252325;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    color: rgba(255, 255, 255, 0.5);
  }

  .ant-statistic-title {
    color: #fff;
  }

  .stat-card {
    background-color: #201f20;
  }

  .ant-menu-submenu-selected
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-selected
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow::after {
    background: #000;
  }

  .ant-btn.ant-dropdown-trigger,
  .ant-picker.ant-picker-range,
  .ant-radio-group.ant-radio-group-solid {
    background: #444244;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before,
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    color: #000;
    background: #ff6725;
    border-color: #ff6725 !important;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value {
    color: #ff7b00;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: #ff7b00;
  }
  .ant-picker-input > input:hover {
    border-color: #ff7b00;
  }
  .ant-picker-input > input:focus,
  .ant-picker-input > input-focused {
    border-color: #ff7b00;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #ff7b00;
  }
  .ant-picker-panel-focused {
    border-color: #ff7b00;
  }
  .ant-picker-header-view button:hover {
    color: #ff7b00;
  }
  .ant-picker-today-btn {
    color: #ff7b00;
  }
  .ant-picker-today-btn:hover {
    color: #ff7b00;
  }
  .ant-picker-today-btn:active {
    color: #ff7b00;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid #ff7b00;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #ff7b00;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: transparent;
    border-right-width: 1px;
  }
  .ant-picker-focused {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
  }

  .ant-form-item.app-config-input-wrap .normal-select .ant-select-selector {
    border: 1px solid #434343;
  }

  .ant-avatar {
    background: #43291a;
    color: #ffffff;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #fff;
    font-weight: 500;
  }

  .as-label {
    background: #141414;
  }
}
