.light-theme {
  background: #f6f6f6;
  color: #707070;

  .ant-layout,
  .header,
  .ant-menu,
  .ant-layout-sider,
  .ant-tabs-nav {
    background: #f6f6f6;
    color: #707070;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .ant-form-item-label > label,
  .ant-form-item,
  .ant-form,
  .ant-upload,
  .ant-typography,
  .ant-input,
  .ant-input-number {
    color: #707070;
  }

  .ant-layout-header {
    .triumph-logo {
      background-image: url("../public/logo_light.svg");
      width: 135px;
      height: 26px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .content-bg {
    background: #fff;
  }

  .ant-menu.ant-menu:not(.ant-menu-horizontal) > .ant-menu-item-selected {
    background: linear-gradient(278.93deg, #f54e03 -15.33%, #fd9f48 92.09%),
      linear-gradient(47.13deg, #f44a00 1.82%, #fd9f48 122.88%), #f6f6f6;
    border-radius: 5px;
  }

  .ant-menu .ant-menu-inline.ant-menu-sub {
    background: #fff;
  }

  .ant-menu.ant-menu:not(.ant-menu-horizontal)
    .ant-menu-sub
    .ant-menu-item-selected {
    color: #000;
  }

  .user-search-multi-select {
    background: #fff;
    color: #707070;
  }

  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  .ant-switch {
    background: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border: none;
    background: #fff;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover {
    color: #000;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  .ant-statistic-title,
  .ant-descriptions-item-label,
  .ant-btn {
    color: #313131;
  }

  .stat-card,
  .ant-card {
    background-color: #fefefe;
    border: 1px solid #d8d7d7;
    border-radius: 5px;
  }

  .ant-statistic-content,
  .ant-descriptions-item-content {
    color: #000 !important;
    font-weight: 500;
  }

  .ant-input,
  .ant-input-number {
    border: 1px solid #d8d7d7;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #d8d7d7;
  }

  .ant-tag-error {
    color: #ff4d4f;
    background: #fff2f0;
    border-color: #ffccc7;
  }

  .ant-tag-success {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }

  .ant-btn {
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
  }

  .ant-select {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #000;
    font-weight: 600;
  }

  /* Ant table*/
  .ant-table.ant-table-bordered > .ant-table-title {
    border: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > td {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr:not(:last-child)
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr:not(:last-child)
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr:not(:last-child)
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr:not(:last-child)
    > th {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th::before,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th::before,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > th::before,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr
    > th::before {
    background-color: transparent !important;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tbody
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > .ant-table-cell-fix-right-first::after {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-footer {
    border: 1px solid #f0f0f0;
  }
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: 0 1px 0 1px #fafafa;
  }
  .ant-table {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
  }
  .ant-table-footer {
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
  }
  .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #fafafa;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #e6f7ff;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #dcf4ff;
  }
  .ant-table-summary {
    background: #fff;
  }
  div.ant-table-summary {
    box-shadow: 0 -1px 0 #f0f0f0;
  }
  .ant-table-summary > tr > th,
  .ant-table-summary > tr > td {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover::before {
    background-color: transparent !important;
  }
  .ant-table-thead th.ant-table-column-has-sorters:focus-visible {
    color: #ff7b00;
  }
  .ant-table-thead
    th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
  .ant-table-thead
    th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
    background: #f5f5f5;
  }
  .ant-table-thead th.ant-table-column-sort {
    background: #f5f5f5;
  }
  .ant-table-thead th.ant-table-column-sort::before {
    background-color: transparent !important;
  }
  td.ant-table-column-sort {
    background: #fafafa;
  }
  .ant-table-column-sorter {
    color: #bfbfbf;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #ff7b00;
  }
  .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #a6a6a6;
  }
  .ant-table-filter-trigger {
    color: #bfbfbf;
  }
  .ant-table-filter-trigger:hover {
    color: rgba(0, 0, 0, 0.45);
    background: rgba(0, 0, 0, 0.04);
  }
  .ant-table-filter-trigger.active {
    color: #ff7b00;
  }
  .ant-table-filter-dropdown {
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
  }
  .ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-table-filter-dropdown-tree
    .ant-tree-treenode
    .ant-tree-node-content-wrapper:hover {
    background-color: #f5f5f5;
  }
  .ant-table-filter-dropdown-tree
    .ant-tree-treenode-checkbox-checked
    .ant-tree-node-content-wrapper,
  .ant-table-filter-dropdown-tree
    .ant-tree-treenode-checkbox-checked
    .ant-tree-node-content-wrapper:hover {
    background-color: #bae7ff;
  }
  .ant-table-filter-dropdown-search {
    border-bottom: 1px #f0f0f0 solid;
  }
  .ant-table-filter-dropdown-search-input .anticon {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-table-filter-dropdown-btns {
    background-color: inherit;
    border-top: 1px solid #f0f0f0;
  }
  table tr th.ant-table-selection-column::after {
    background-color: transparent !important;
  }
  .ant-table-selection-extra .anticon {
    color: #bfbfbf;
  }
  .ant-table-selection-extra .anticon:hover {
    color: #a6a6a6;
  }
  .ant-table-row-expand-icon {
    color: #ff7b00;
    transition: color 0.3s;
    background: #fff;
    border: 1px solid #f0f0f0;
  }
  .ant-table-row-expand-icon:focus-visible,
  .ant-table-row-expand-icon:hover {
    color: #ff7b00;
  }
  .ant-table-row-expand-icon:active {
    color: #ff7b00;
  }
  .ant-table-row-expand-icon-spaced {
    background: transparent;
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #fbfbfb;
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #fff;
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: #fff;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left)
    > .ant-table-container::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-ping-left .ant-table-cell-fix-left-last::before {
    background-color: transparent !important;
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right)
    > .ant-table-container::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after,
  .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-sticky-holder {
    background: #fff;
  }
  .ant-table-sticky-scroll {
    background: #ffffff;
    border-top: 1px solid #f0f0f0;
    opacity: 0.6;
  }
  .ant-table-sticky-scroll-bar {
    background-color: rgba(0, 0, 0, 0.35);
  }
  .ant-table-sticky-scroll-bar:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .ant-table-sticky-scroll-bar-active {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .ant-btn.ant-dropdown-trigger,
  .ant-picker.ant-picker-range,
  .ant-radio-group.ant-radio-group-solid {
    background: #fff;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-picker-calendar {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
  }
  .ant-picker-calendar .ant-picker-panel {
    background: #fff;
    border-top: 1px solid #f0f0f0;
  }
  .ant-picker-calendar-full .ant-picker-panel {
    background: #fff;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell:hover
    .ant-picker-calendar-date {
    background: #f5f5f5;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today {
    background: #e6f7ff;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: 2px solid #f0f0f0;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-content {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: #ff7b00;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value {
    color: rgba(0, 0, 0, 0.85);
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: border 0.3s, box-shadow 0.3s;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #d9d9d9;
    border-right-width: 1px;
  }
  .ant-picker-focused {
    border-color: #d9d9d9;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
  }
  .ant-picker.ant-picker-disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker.ant-picker-borderless {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .ant-picker-input > input {
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    border: 1px solid #fff;
    background: transparent;
  }
  .ant-picker-input > input::-moz-placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input::placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input:hover {
    color: #ff7b00;
  }
  .ant-picker-input > input:focus,
  .ant-picker-input > input-focused {
    // color: #ff7b00;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-picker-input > input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .ant-picker-input > input-disabled:hover {
    border-color: #f5f5f5;
  }
  .ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  .ant-picker-input > input[disabled]:hover {
    border-color: #f5f5f5;
  }
  .ant-picker-input > input-borderless,
  .ant-picker-input > input-borderless:hover,
  .ant-picker-input > input-borderless:focus,
  .ant-picker-input > input-borderless-focused,
  .ant-picker-input > input-borderless-disabled,
  .ant-picker-input > input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .ant-picker-input-placeholder > input {
    color: #bfbfbf;
  }
  .ant-picker-suffix {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-clear {
    color: rgba(0, 0, 0, 0.25);
    background: #fff;
  }
  .ant-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-separator {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-focused .ant-picker-separator {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #ff7b00;
  }
  .ant-picker-dropdown {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: #ff7b00;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
  }

  .ant-picker-range-arrow {
    box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  }
  .ant-picker-range-arrow::before {
    background: #fff;
  }
  .ant-picker-panel-container {
    background: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-picker-panel-container .ant-picker-panel-focused {
    border-color: #f0f0f0;
  }
  .ant-picker-panel {
    text-align: center;
    background: #fff;
    border: 1px solid #f0f0f0;
  }
  .ant-picker-panel-focused {
    border-color: #ff7b00;
  }
  .ant-picker-header {
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-header button {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
  }
  .ant-picker-header > button:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-header-view button:hover {
    color: #ff7b00;
  }
  .ant-picker-content th {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-cell {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end):not(
      .ant-picker-cell-range-hover-start
    ):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: #f5f5f5;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #e6f7ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #ff7b00;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before {
    background: #e6f7ff;
  }
  .ant-picker-cell-disabled {
    color: rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: transparent;
  }
  .ant-picker-cell-disabled::before {
    background: rgba(0, 0, 0, 0.04);
  }
  .ant-picker-cell-disabled.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #f0f0f0;
  }
  .ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-today-btn {
    color: #ff7b00;
  }
  .ant-picker-today-btn:hover {
    color: #ff7b00;
  }
  .ant-picker-today-btn:active {
    color: #ff7b00;
  }
  .ant-picker-today-btn.ant-picker-today-btn-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-picker-week-panel-row:hover td {
    background: #f5f5f5;
  }
  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: #ff7b00;
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
  }
  .ant-picker-week-panel-row-selected
    td.ant-picker-cell-today
    .ant-picker-cell-inner::before,
  .ant-picker-week-panel-row-selected:hover
    td.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: #fff;
  }
  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
  .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
    color: #fff;
  }
  .ant-picker-datetime-panel .ant-picker-time-panel {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-time-panel-column-active {
    background: rgba(230, 247, 255, 0.2);
  }
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner:hover {
    background: #f5f5f5;
  }
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: #e6f7ff;
  }
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-disabled
    .ant-picker-time-panel-cell-inner {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    cursor: not-allowed;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid #ff7b00;
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-picker-calendar {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
  }

  .ant-picker-calendar .ant-picker-panel {
    background: #fff;
    border-top: 1px solid #f0f0f0;
  }

  .ant-picker-calendar-full .ant-picker-panel {
    background: #fff;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell:hover
    .ant-picker-calendar-date {
    background: #f5f5f5;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today {
    background: #e6f7ff;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:hover
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value {
    color: #ff7b00;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: 2px solid #f0f0f0;
    transition: background 0.3s;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-content {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: #ff7b00;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner {
    background: #ff7b00;
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-radio-group {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
    border-left: none;
  }
  .ant-radio-wrapper {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-radio {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
  }
  .ant-radio-checked::after {
    border: 1px solid #ff7b00;
  }
  .ant-radio-inner {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .ant-radio-inner::after {
    background-color: #ff7b00;
  }
  .ant-radio.ant-radio-disabled .ant-radio-inner {
    border-color: #d9d9d9;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #ff7b00;
  }
  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-radio-disabled {
    cursor: not-allowed;
  }
  .ant-radio-disabled .ant-radio-inner {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-radio-disabled .ant-radio-inner::after {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ant-radio-disabled .ant-radio-input {
    cursor: not-allowed;
  }
  .ant-radio-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-radio-button-wrapper {
    color: rgba(0, 0, 0, 0.85);

    background: #fff;
    border: 1px solid #d9d9d9;

    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  }
  .ant-radio-button-wrapper a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #d9d9d9;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #ff7b00;
  }
  .ant-radio-button-wrapper:focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #ff7b00;
    background: #fff;
    border-color: #ff7b00;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: #ff7b00;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):first-child {
    border-color: #ff7b00;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    color: #ff7b00;
    border-color: #ff7b00;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover::before {
    background-color: #ff7b00;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):active {
    color: #ff7b00;
    border-color: #ff7b00;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):active::before {
    background-color: #ff7b00;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #ff7b00;
    border-color: #ff7b00;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    color: #fff;
    background: #ff7b00;
    border-color: #ff7b00;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):active {
    color: #fff;
    background: #ff7b00;
    border-color: #ff7b00;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
  }
  .ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-radio-button-wrapper-disabled:first-child,
  .ant-radio-button-wrapper-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-radio-button-wrapper-disabled:first-child {
    border-left-color: #d9d9d9;
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: rgba(0, 0, 0, 0.25);
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    box-shadow: none;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
    border-right: 1px solid #d9d9d9;
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    border-right-color: #ff7b00;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
    border-right-color: #d9d9d9;
  }
  .ant-radio-button-wrapper:first-child {
    border-right: none;
  }
  .ant-radio-button-wrapper:last-child {
    border-left: none;
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: #ff4d4f;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
  }
  .ant-dropdown {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-dropdown-arrow::before {
    background: #fff;
    background-repeat: no-repeat;
    background-position: -10px -10px;
    content: "";
    -webkit-clip-path: inset(33% 33%);
    clip-path: inset(33% 33%);
    -webkit-clip-path: path(
      "M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z"
    );
    clip-path: path(
      "M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z"
    );
  }
  .ant-dropdown-placement-top > .ant-dropdown-arrow,
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  }
  .ant-dropdown-placement-bottom > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  }
  .ant-dropdown-menu {
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-dropdown-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-dropdown-menu-title-content > a:hover {
    color: inherit;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected {
    color: #ff7b00;
    background-color: #e6f7ff;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
    background-color: #f5f5f5;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-disabled:hover,
  .ant-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    background-color: #f0f0f0;
  }
  .ant-dropdown-menu-item
    .ant-dropdown-menu-submenu-expand-icon
    .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-menu-submenu-title
    .ant-dropdown-menu-submenu-expand-icon
    .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
    .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
    .ant-dropdown-menu-submenu-title
    .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: #ff7b00;
  }
  .ant-dropdown-menu-dark,
  .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #001529;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-item
    .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-submenu-title
    .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-item
    > a
    .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-item
    > .anticon
    + span
    > a
    .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
    color: #fff;
    background: transparent;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    color: #fff;
    background: #ff7b00;
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-pagination {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-pagination-item {
    background-color: #fff;
    border: 1px solid #d9d9d9;
  }
  .ant-pagination-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-pagination-item:hover {
    border-color: #ff6725;
  }
  .ant-pagination-item:hover a {
    color: #ff6725;
  }
  .ant-pagination-item:focus-visible {
    border-color: #ff6725;
  }
  .ant-pagination-item:focus-visible a {
    color: #ff6725;
  }
  .ant-pagination-item-active {
    background: #fff;
    border-color: #ff6725;
  }
  .ant-pagination-item-active a {
    color: #ff6725;
  }
  .ant-pagination-item-active:hover {
    border-color: #ff6725;
  }
  .ant-pagination-item-active:focus-visible {
    border-color: #ff6725;
  }
  .ant-pagination-item-active:hover a {
    color: #ff6725;
  }
  .ant-pagination-item-active:focus-visible a {
    color: #ff6725;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #ff6725;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-pagination-prev button,
  .ant-pagination-next button {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-pagination-prev:hover button,
  .ant-pagination-next:hover button {
    border-color: #ff6725;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: #fff;
    border: 1px solid #d9d9d9;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link {
    color: #ff6725;
    border-color: #ff6725;
  }
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #ff6725;
    border-color: #ff6725;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
  }
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
  }
  .ant-pagination-options-quick-jumper input {
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
  }
  .ant-pagination-options-quick-jumper input::-moz-placeholder {
    color: #bfbfbf;
  }
  .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-pagination-options-quick-jumper input::placeholder {
    color: #bfbfbf;
  }
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #ff6725;
    border-right-width: 1px;
  }
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input-focused {
    border-color: #ff6725;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-pagination-options-quick-jumper input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #d9d9d9;
  }
  .ant-pagination-options-quick-jumper input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #d9d9d9;
  }
  .ant-pagination-options-quick-jumper input-borderless,
  .ant-pagination-options-quick-jumper input-borderless:hover,
  .ant-pagination-options-quick-jumper input-borderless:focus,
  .ant-pagination-options-quick-jumper input-borderless-focused,
  .ant-pagination-options-quick-jumper input-borderless-disabled,
  .ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: #fff;
    border: 1px solid #d9d9d9;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #ff6725;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input:focus {
    border-color: #ff6725;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-pagination.ant-pagination-mini
    .ant-pagination-item:not(.ant-pagination-item-active) {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.ant-pagination-mini
    .ant-pagination-prev
    .ant-pagination-item-link,
  .ant-pagination.ant-pagination-mini
    .ant-pagination-next
    .ant-pagination-item-link {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border: none;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: #e6e6e6;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
  .ant-pagination-simple.ant-pagination.ant-pagination-disabled
    .ant-pagination-item-link {
    background: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(0, 0, 0, 0.25);
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-modal {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .ant-modal-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-modal-content {
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-modal-close {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-modal-close:focus,
  .ant-modal-close:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-modal-header {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-modal-footer {
    background: transparent;
    border-top: 1px solid #f0f0f0;
  }
  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: #ff4d4f;
  }
  .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
  .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: #faad14;
  }
  .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #1890ff;
  }
  .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #52c41a;
  }

  .ant-card {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
  }
  .ant-card-head {
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-select-arrow {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-avatar {
    color: #000;
    background: #eeeeee;
  }

  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #000;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    color: #707070;
  }

  .as-label {
    background: #fff;
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-empty {
    margin: 0 8px;
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
  }
  .ant-empty-image {
    height: 100px;
    margin-bottom: 8px;
  }
  .ant-empty-image img {
    height: 100%;
  }
  .ant-empty-image svg {
    height: 100%;
    margin: auto;
  }
  .ant-empty-footer {
    margin-top: 16px;
  }
  .ant-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-empty-normal .ant-empty-image {
    height: 40px;
  }
  .ant-empty-small {
    margin: 8px 0;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-empty-small .ant-empty-image {
    height: 35px;
  }
  .ant-empty-img-default-ellipse {
    fill: #f5f5f5;
    fill-opacity: 0.8;
  }
  .ant-empty-img-default-path-1 {
    fill: #aeb8c2;
  }
  .ant-empty-img-default-path-2 {
    fill: url("#linearGradient-1");
  }
  .ant-empty-img-default-path-3 {
    fill: #f5f5f7;
  }
  .ant-empty-img-default-path-4 {
    fill: #dce0e6;
  }
  .ant-empty-img-default-path-5 {
    fill: #dce0e6;
  }
  .ant-empty-img-default-g {
    fill: #fff;
  }
  .ant-empty-img-simple-ellipse {
    fill: #000;
  }
  .ant-empty-img-simple-g {
    stroke: #d9d9d9;
  }
  .ant-empty-img-simple-path {
    fill: #fafafa;
    stroke: #d9d9d9;
  }
  .ant-empty-rtl {
    direction: rtl;
  }

  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-steps {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-icon {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-icon .ant-steps-icon {
    color: #ff7b00;
  }
  .ant-steps-item-tail::after {
    background: #f0f0f0;
  }
  .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-title::after {
    background: #f0f0f0;
  }
  .ant-steps-item-subtitle {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-wait
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: rgba(0, 0, 0, 0.25);
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: #ff7b00;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #ff7b00;
  }
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #ff7b00;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #ff7b00;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #ff7b00;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #ff7b00;
  }
  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #ff7b00;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #ff7b00;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #ff7b00;
  }
  .ant-steps-item-error .ant-steps-item-icon {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
    color: #ff4d4f;
  }
  .ant-steps-item-error
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #ff4d4f;
  }
  .ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #ff4d4f;
  }
  .ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f0f0f0;
  }
  .ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: #ff4d4f;
  }
  .ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #f0f0f0;
  }
  .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
    background: #ff4d4f;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-title,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-subtitle,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-description {
    color: #ff7b00;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon {
    border-color: #ff7b00;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: #ff7b00;
  }
  .ant-steps-item-custom.ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon {
    color: #ff7b00;
  }
  .ant-steps-small .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
  .ant-steps-dot.ant-steps-small
    .ant-steps-item-icon
    .ant-steps-icon-dot::after {
    background: rgba(0, 0, 0, 0.001);
  }
  .ant-steps-navigation .ant-steps-item::after {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .ant-steps-navigation .ant-steps-item::before {
    background-color: #ff7b00;
  }
}
